<template>
  <div>
    <div class="row">
      <h1 class="col-sm-12 text-right mt-4 mb-4">Solicitudes</h1>
    </div>

    <div class="row my-3">
      <div class="col-sm-3 offset-sm-9">
        <div class="input-group mb-3">
          <input v-model="buscar" type="text" class="form-control" placeholder="Buscar ID...">
          <div class="input-group-append">
            <button class="btn btn-primary">Buscar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row card-solicitud">
      <div v-for="solicitud in solicitudes_filtradas" :key="solicitud.id" class="col-lg-6 col-md-12">
        <div class="card">
          <h4 class="card-title text-center">
            <span>Solicitud: {{ solicitud.solicitud }}</span>
          </h4>
          <div class="card-body">

            <div  class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Nombre</span>
              <span class="col text-right"> {{solicitud.nombre}} </span>
            </div>
            <!-- Notario -->

            <div v-if="$auth.can('formalizacion','formalizacion_notario')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Sub asignación</span>
              <span class="col text-right"> {{ buscar_sla(solicitud,"subasignacion") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_notario')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Documentación básica</span>
              <span class="col text-right"> {{ buscar_sla(solicitud,"documentacion_basica") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_notario')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Dictamen cliente</span>
              <span class="col text-right"> {{ buscar_sla(solicitud,"dictamen_cliente") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_notario')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Dictamen inmueble</span>
              <span class="col text-right"> {{ buscar_sla(solicitud,"dictamen_inmueble") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_notario')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Solicitud de CLG</span>
              <span class="col text-right" > {{ buscar_sla(solicitud,"solicitud_clg") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_notario')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Carga de CLG</span>
              <span class="col text-right" > {{ buscar_sla(solicitud,"cargar_clg") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_notario')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Documentación completa</span>
              <span class="col text-right" > {{ buscar_sla(solicitud,"documentacion_completa") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_notario')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Cita para Firma</span>
              <span class="col text-right" > {{ buscar_sla(solicitud,"firma_cita") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_notario')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Contrato firmado</span>
              <span class="col text-right" > {{ buscar_sla(solicitud,"contrato_firmado") }} </span>
            </div>

            <!-- Valuador -->

            <div v-if="$auth.can('formalizacion','formalizacion_evaluador')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Entidad</span>
              <span class="col text-right"> {{ solicitud.estado }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_evaluador')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Se generó cita valuación (fecha)</span>
              <span class="col text-right"> {{ buscar_sla(solicitud,"cita_valuacion") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_evaluador')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Fecha de contacto al cliente (cita valuación)</span>
              <span class="col text-right"> {{ cita_valuacion(solicitud) }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_evaluador')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Carga de valuación</span>
              <span class="col text-right"> {{ buscar_sla(solicitud,"carga_valuacion") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_evaluador')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Incidencias</span>
              <span class="col text-right"> {{ buscar_sla(solicitud,"incidencias_valuacion") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_evaluador')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Costos</span>
              <span class="col text-right"> {{ buscar_sla(solicitud,"costos_valuacion") }} </span>
            </div>

            <div v-if="$auth.can('formalizacion','formalizacion_evaluador')" class="d-flex justify-content-between align-items-end list-item">
              <span class="col">Pago de factura</span>
              <span class="col text-right"> {{ buscar_sla(solicitud,"pago_factura") }} </span>
            </div>

            <div class="mt-4">
              <template v-if="usuario.tipo == 2">
                <router-link :to="{name: 'formalizacion_V2_usuarios_solicitudes_notario', params:{solicitud: solicitud.solicitud }}" class="btn complementary-btn">Ver solicitud</router-link>
              </template>
              <template v-else>
                <router-link :to="{name: 'formalizacion_V2_usuarios_solicitudes_valuador', params:{solicitud: solicitud.solicitud }}" class="btn complementary-btn">Ver solicitud</router-link>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import api from '@/apps/formalizacion/api/v2/usuarios';

  export default {
    data: () => ({
      solicitudes: []
      ,usuario: {
        tipo: 1
      }
      ,buscar: null
    })
    ,mounted() {
      this.obtener_solicitudes();
    }
    ,methods: {
      async obtener_solicitudes() {
        try {
          this.usuario = (await api.obtener_solicitudes()).data;
          this.solicitudes = this.usuario.solicitudes;
        }catch(e) {
          this.$log.info('error',e)
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_sla(solicitud, sla) {
        let lista_sla = new Object();

        solicitud.sla.forEach(sla => {
          lista_sla[sla.tipo] = sla.created_at;
        })

        return Object.keys(lista_sla).indexOf(sla) > -1 ? this.$moment(lista_sla[sla]).format('YYYY-MM-DD HH:mm') : 'N/A';
      }
      ,cita_valuacion(solicitud){
        return  !solicitud.cita_valuacion ? 'N/A' : this.$moment(solicitud.cita_valuacion.date).format('YYYY-MM-DD HH:mm');
      }
    }
    ,computed: {
      solicitudes_filtradas() {
        let solicitudes = [];

        this.solicitudes.forEach(solicitud => {
          if (!this.buscar || String(solicitud.solicitud).indexOf(this.buscar) != -1)
            solicitudes.push(solicitud);
        })

        return solicitudes;
      }
    }
  }
</script>

<style lang="scss" scope>
  .card-solicitud {
    .card-title {
      padding: 1rem;
      margin-bottom: 0;
      background-color: #056EAF;
      
      span {
        color: #fff !important;
        font-weight: 700;
      }
    }
  }

  .card-body {
    .list-item {
      padding: .5rem 0;
      &:nth-child(2n+1) {
        background-color: #E6E6E6;
      }

      .text-right {
        font-weight: bolder;
      }
    }
  }
</style>